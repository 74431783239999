import React, { useEffect, useRef, useState } from "react";
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { useNavigate } from "react-router-dom";
import { ReactComponent as DownArrow } from "../../assets/images/icons/caret-down-solid.svg";
import { ReactComponent as SideArrow } from "../../assets/images/icons/caret-right-solid.svg";
import { ReactComponent as SidebarSideArrow } from "../../assets/images/icons/sidebar-arrow.svg";
import { ReactComponent as BasicSearch } from "../../assets/images/icons/basicSearch.svg";
import { ReactComponent as GptSearch } from "../../assets/images/icons/gptSearch.svg";
import { ReactComponent as AttributesSearch } from "../../assets/images/icons/attributesSearch.svg";
import { fetchCachedFilters } from "../../utilities/fetchingCacheFilters.util";
import { ReactComponent as FilterExplain } from "../../assets/images/icons/question-mark.svg";
import filter_explain from "../../assets/images/koala/filter_explain_image.png";
import "./sidebar.scss";
import {
  Attribute,
  AttributeValue,
  SearchOption,
  AttributeToggle,
  SelectedType,
  SearchType,
} from "../../types";
import { useAttributeContext } from "../../context/UserAttributeContext";
import { fetchSharedCachedFilters } from "../../models/shared-board.model";
import Swal from "sweetalert2";

export type SidebarProps = {
  selectType: SelectedType;
  clientID: string;
  boardID?: string;
  searchOptions: SearchOption[];
  setSelectedSearchType: (searchType: SearchType) => void;
  selectedSearchType: SearchType;
  selectedFilters: Record<string, Record<string, string[]>>;
  setSelectedFilters: (
    selectedFilters: Record<string, Record<string, string[]>>
  ) => void;
  setSearchWord: (searchWord: string) => void;
  setGptSearch: (gptSearch: string) => void;
  btnSearchClicked?: boolean;
  setBtnSearchClicked?: (btnSearch: boolean) => void;
  andOrToggle?: AttributeToggle;
  setAndOrToggle?: (andOrToggle: AttributeToggle) => void;
};

const Sidebar: React.FC<SidebarProps> = ({
  selectType,
  clientID,
  boardID,
  searchOptions,
  setSelectedSearchType,
  selectedSearchType,
  selectedFilters,
  setSelectedFilters,
  setSearchWord,
  setGptSearch,
  btnSearchClicked,
  setBtnSearchClicked,
  andOrToggle,
  setAndOrToggle,
}) => {
  const [filters, setFilters] = useState<Attribute>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Declare attributeSearch as an object with string keys and string values
  const [attributeSearch, setAttributeSearch] = useState<
    Record<string, string>
  >({});
  const [attributeMessage, setAttributeMessage] = useState<string | null>(null);
  // For setting tags
  const [searchTags, setSearchTags] = useState<string[]>([]);
  const inputRef = useRef<HTMLInputElement | null>(null);
  // filterIsOpen keeps track of whether each dropdown in the filter section is open or closed. Initialized as an array of booleans, with each element set to false.
  const [filterIsOpen, setFilterIsOpen] = useState<boolean[]>(
    Array(Object.keys(filters).length).fill(false)
  );
  const [selectedCount, setSelectedCount] = useState<number[]>(
    Array(Object.keys(filters).length).fill(0)
  );
  const [arrowDirections, setArrowDirections] = useState<Array<string>>(
    Array(Object.keys(filters).length).fill("side")
  );
  // Display of Attributes Explanation Popup
  const [displayAttributesExplain, setDisplayAttributesExplain] =
    useState<Boolean>(false);

  const userAttributeContext = useAttributeContext();

  const [buildingFilters, setBuildingFilters] = useState<string>("none");

  const toggleArrowDirection = (index: number) => {
    setArrowDirections((prevState) => {
      const newState = [...prevState];
      newState[index] = prevState[index] === "side" ? "up" : "side";
      return newState;
    });
  };

  const backgroundImageStyle = `url(../../../../assets/images/koala/filter_explain_image.png)`;

  //Fetch cache data
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (selectType === "shared") {
      let splitClientID = clientID.split(":");
      let boardID = splitClientID[0];
      let shareToken = splitClientID[1];
      const loadSharedFilters = async () => {
        setIsLoading(true);
        let filtersData = await fetchSharedCachedFilters(shareToken, boardID);
        setIsLoading(false);
        if (typeof filtersData === "string") {
          setFilters({});
        } else {
          if (filtersData.statusCode == 200) {
            setFilters(filtersData.response);
          } else if (filtersData.statusCode == 204) {
            setAttributeMessage("No filters found");
            setFilters({});
          } else if (filtersData.statusCode == 206) {
            setAttributeMessage("Loading data. Please be patient");
            setFilters({});
            setTimeout(() => {
              loadSharedFilters();
            }, 30000);
          } else {
            setAttributeMessage(null);
            setFilters({});
          }
        }
      };
      loadSharedFilters();
    } else {
      const loadFilters = async () => {
        setIsLoading(true);
        const filterData = await fetchCachedFilters(
          selectType,
          clientID,
          boardID ? boardID : null,
          signal,
          userAttributeContext,
        );
        setIsLoading(false);
        if (typeof filterData !== "string") {
          if (filterData.statusCode === 204) {
            setAttributeMessage("No filters found");
            setFilters({});
          } else if (filterData.statusCode === 206) {
            setAttributeMessage("Loading data. Please be patient");
            setFilters({});
            setTimeout(() => {
              loadFilters();
            }, 30000);
          } else {
            setAttributeMessage(null);
            setFilters(filterData.response);
          }
        } else {
          setAttributeMessage("Failed to fetch filters");
          setFilters({});
        }
      };
      loadFilters();
    }

    return () => {
      controller.abort();
    };
  }, []);


  // Toggle Filter Explanation Window
  useEffect(() => {
    console.log("Called for update", displayAttributesExplain);
    displayAttributesExplain &&
      Swal.fire({
        html: `<div class="modal-explanation">
        <img src="${filter_explain}" alt="Explanation Image" />
      <div class="overlay-text">
        <p>Selecting multiple Values within a single Attribute works as an "OR" condition, meaning the system will show content that matches any of the chosen Values. When you choose Values from different Attributes, it uses an "AND" condition, so the content must meet all the selected criteria across the different Attributes.</p>
      </div>
    </div>`,
        showCloseButton: false,
        allowOutsideClick: false,
        confirmButtonText: "Close",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setDisplayAttributesExplain(false);
        }
      });
  }, [displayAttributesExplain]);

  const handleDropdownClick = (index: number) => {
    setFilterIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
    toggleArrowDirection(index);

    // Shows full filter heading when selected
    const headingElement = document.querySelector(`.h3FilterHeading-${index}`);
    headingElement?.classList.toggle("fullHeading");
  };

  const handleOptionClick = (
    value: string,
    attributeID: string,
    attributeObject: AttributeValue
  ) => {
    //set selected value so the check value is checked
    const updatedFilters: Record<string, Record<string, Set<string>>> = {};

    // Convert existing filters to use Set
    for (const key in selectedFilters) {
      updatedFilters[key] = {};
      for (const subKey in selectedFilters[key]) {
        updatedFilters[key][subKey] = new Set(selectedFilters[key][subKey]);
      }
    }

    const attributeImages = attributeObject[value] || [];

    if (!updatedFilters[attributeID]) {
      updatedFilters[attributeID] = {};
    }

    if (!updatedFilters[attributeID][value]) {
      updatedFilters[attributeID][value] = new Set();
    }

    if (attributeImages.length > 0) {
      attributeImages.forEach((image) => {
        if (!updatedFilters[attributeID][value].has(image)) {
          updatedFilters[attributeID][value].add(image);
        } else {
          updatedFilters[attributeID][value].delete(image);
        }
      });
    } else {
      if (updatedFilters[attributeID][value].size === 0) {
        updatedFilters[attributeID][value].add("no images");
      } else {
        updatedFilters[attributeID][value].delete("no images");
        if (updatedFilters[attributeID][value].size === 0) {
          delete updatedFilters[attributeID][value];
        }
      }
    }

    // Clean up empty entries
    for (const key in updatedFilters) {
      for (const subKey in updatedFilters[key]) {
        if (
          updatedFilters[key][subKey].size === 0 ||
          (updatedFilters[key][subKey].size === 1 &&
            updatedFilters[key][subKey].has(""))
        ) {
          delete updatedFilters[key][subKey];
        }
      }
      if (Object.keys(updatedFilters[key]).length === 0) {
        delete updatedFilters[key];
      }
    }

    // Convert Sets back to arrays before updating the state
    const finalFilters: Record<string, Record<string, string[]>> = {};
    for (const key in updatedFilters) {
      finalFilters[key] = {};
      for (const subKey in updatedFilters[key]) {
        finalFilters[key][subKey] = Array.from(updatedFilters[key][subKey]);
      }
    }

    setSelectedFilters(finalFilters);
  };

  const handleClearFilter = () => {
    setSelectedCount(Array(Object.keys(filters).length).fill(0));
    setSearchTags([]);
    setSelectedFilters({});
  };

  const [searchString, setSearchString] = useState<string>("");
  const [gptString, setGptString] = useState<string>("");
  // State to toggle
  const [isGPT, setIsGPT] = useState<boolean>(true);

  // Go button: Smart search or Keyword search
  const handleSearch = (searchType: string) => {
    if (searchType === "smart") {
      setGptSearch(gptString.trim());
    } else {
      setSearchWord(searchString.trim());
    }
    if (setBtnSearchClicked) {
      setBtnSearchClicked(!btnSearchClicked);
    }
  };

  // variables to store the visibility of the sidebar and the sidebar button
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

  // handleSidebarClick function is triggered when the sidebar is clicked to hide the sidebar.
  const handleSidebarClick = (boolSideBar: boolean) => {
    setSidebarVisible(boolSideBar);
  };

  // Toggle switch for GPT and Keyword
  const handleToggleSwitch = (checked: boolean) => {
    setIsGPT(checked);

    // Clear all tags when toggling
    setSearchTags([]);

    // Clear smart search input
    setSearchString("");
  };

  // Resize the toggle switch at 1160px
  const [toggleSwitchSmall, setToggleSwitchSmall] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Get the viewport width
      const viewportWidth =
        window.innerWidth || document.documentElement.clientWidth;

      // Determine whether to apply the small prop based on the viewport width
      const applySmallAt1160 = viewportWidth <= 1160;

      const applyLargeAt960 = viewportWidth <= 960;

      // Set the small prop accordingly
      setToggleSwitchSmall(applySmallAt1160 && !applyLargeAt960);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleChangeSearchType = (searchType: string) => {
    setSelectedSearchType(searchType as "smart" | "search" | "attributes");
  };

  const loadFilterData = (attribute: any, attributeID: any, attributeName: any) => {
    // Filter data based on the search term
    const data = Object.keys(attribute[attributeName]).filter((value) =>
      value.toLowerCase().includes(attributeSearch[attributeID])
    );
  
    console.log(data.length); // Check the length of the filtered data
  
    // Row component, memoized to prevent unnecessary re-renders
    const Row: React.FC<ListChildComponentProps> = React.memo(({ index, style }) => {
      const value = data[index];
      
      return (
        <div style={{ marginTop: "5px", ...style }} key={index}>
          <li>
            <label className="side_filter_item">
              <h6>
                <input
                  className="uk-checkbox"
                  type="checkbox"
                  onChange={() =>
                    handleOptionClick(value, attributeID, attribute[attributeName])
                  }
                  checked={
                    selectedFilters[attributeID] &&
                    selectedFilters[attributeID][value] &&
                    selectedFilters[attributeID][value].length > 0
                  }
                />{" "}
                <span style={{overflowY: "hidden"}}>{value}</span>
              </h6>
            </label>
          </li>
        </div>
      );
    });
  
    return (
      <>
        {/* Search input field */}
        <input
          type="text"
          className="searchFilter"
          placeholder="start typing..."
          onChange={(event) =>
            setAttributeSearch({
              ...attributeSearch,
              [attributeID]: event.target.value.toLowerCase(),
            })
          }
        />
        
        {/* Conditionally render the list based on search input */}
        {attributeSearch[attributeID] && attributeSearch[attributeID] !== "" && (
          data.length > 0 ?(
            <List
            style={{marginBlockStart: "10px", marginTop: "10px" }}
            height={data.length * 35 < 200 ? data.length * 35: 200}  // Set a fixed height when there is a search term
            itemCount={data.length}  // Total number of filtered items
            itemSize={35}  // Height of each item (fixed)
            width={400}  // Width of the list
            overscanCount={4}  // Render additional items off-screen for smooth scrolling
          >
            {Row}
          </List>
          ) :(
            <div style={{marginBlockStart: "10px"}}>
                 <span className="filterHeadingTitle" style={{marginLeft: "10px"}}> No Results</span>
            </div>
           
          )

        )}
      </>
    );
  };
  

  return (
    <div className={`sidebar ${!sidebarVisible ? "hidden" : ""}`}>
      <div className="sidebar-headers">
        {(searchOptions.includes("all") ||
          searchOptions.includes("search")) && (
          <BasicSearch
            className={`header-icons ${
              selectedSearchType === "search" ? "selected" : ""
            }`}
            onClick={() => handleChangeSearchType("search")}
            uk-tooltip="title: Keyword Search; pos: right"
          />
        )}
        {/* ======================SMART SEARCH==================== */}
        {(searchOptions.includes("all") || searchOptions.includes("smart")) && (
          <GptSearch
            className={`header-icons ${
              selectedSearchType === "smart" ? "selected" : ""
            }`}
            onClick={() => handleChangeSearchType("smart")}
            uk-tooltip="title: Smart Search; pos: right"
          />
        )}
        <div className="search-wrapper">
          {(searchOptions.includes("all") ||
            searchOptions.includes("attributes")) && (
            <div className="attributes-search-wrapper">
              <AttributesSearch
                className={`search-icon ${
                  selectedSearchType === "attributes" ? "selected" : ""
                }`}
                onClick={() => handleChangeSearchType("attributes")}
                uk-tooltip="title: Attribute Search; pos: left"
              />
              {selectedSearchType === "attributes" && (
                <FilterExplain
                  className="filter-explain"
                  onClick={() => {
                    displayAttributesExplain === true
                      ? setDisplayAttributesExplain(false)
                      : setDisplayAttributesExplain(true);
                  }}
                  uk-tooltip="title: Explain 'MIX'; pos: right"
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div id="sidebar_section_search">
        {/* ======================Keyword SEARCH==================== */}
        {selectedSearchType === "search" && (
          <div className="tagArea uk-align-center">
            <input
              ref={inputRef}
              className="inputSearch"
              //isGpt wont be true here
              placeholder="Search here"
              onChange={(event) => setSearchString(event.target.value)}
              value={searchString ? searchString : ""}

              // uk-tooltip="title: Press Enter after typing in a keyword; pos: top"
            />
            <button
              className="dashButton uk-align-center"
              uk-tooltip="title: Search here; pos: right"
              onClick={() => handleSearch("search")}
            >
              GO
            </button>
          </div>
        )}
        {/* ======================SMART SEARCH==================== */}
        {selectedSearchType === "smart" && (
          <div className="tagArea">
            <input
              className="inputSearch"
              placeholder="Smart search"
              onChange={(event) => setGptString(event.target.value)}
              value={gptString ? gptString : ""}
            />
            <button
              className="dashButton"
              onClick={() => handleSearch("smart")}
            >
              GO
            </button>
          </div>
        )}
        {/* ======================Filter SEARCH==================== */}
        {selectedSearchType === "attributes" && (
          <div className="uk-margin-small side_filter_container attributes">
            <div className="side_filter_container_inner">
              {/* Search Attribute */}
              {isLoading ? (
                <div className="loader">Loading</div>
              ) : (
                <>
                  {/* Disabled Filter Options*/}
                  {/* <div className="radioGroup">
                    <label
                      className={
                        andOrToggle === "AND"
                          ? "radioButton andBtn selected"
                          : "radioButton andBtn"
                      }
                    >
                      <input
                        type="radio"
                        value="AND"
                        checked={andOrToggle === "AND"}
                        onChange={() => setAndOrToggle && setAndOrToggle("AND")}
                      />
                      <span className="radioLabel">AND</span>
                    </label>
                    <label
                      className={
                        andOrToggle === "OR"
                          ? "radioButton orBtn selected "
                          : "radioButton orBtn"
                      }
                    >
                      <input
                        type="radio"
                        value="OR"
                        checked={andOrToggle === "OR"}
                        onChange={() => setAndOrToggle && setAndOrToggle("OR")}
                      />
                      <span className="radioLabel">OR</span>
                    </label>
                    <label
                      className={
                        andOrToggle === "MIX"
                          ? "radioButton mixedBtn selected"
                          : "radioButton mixedBtn"
                      }
                    >
                      <input
                        type="radio"
                        value="MIX"
                        checked={andOrToggle === "MIX"}
                        onChange={() => setAndOrToggle && setAndOrToggle("MIX")}
                      />
                      <span className="radioLabel">MIX</span>
                    </label>
                    <label
                      className={
                        andOrToggle === "REL"
                          ? "radioButton relatedBtn selected"
                          : "radioButton relatedBtn"
                      }
                    >
                      <input
                        type="radio"
                        value="REL"
                        checked={andOrToggle === "REL"}
                        onChange={() => setAndOrToggle && setAndOrToggle("REL")}
                      />
                      <span className="radioLabel">REL</span>
                    </label>
                  </div> */}
                  {Object.keys(filters).length > 0 ? (
                    Object.entries(filters).map(
                      ([attributeID, attribute], index) => (
                        <dl className="dropdown" key={attributeID}>
                          <dt className="containSelection">
                            <div onClick={() => handleDropdownClick(index)}>
                              {Object.entries(attribute).map(
                                ([attributeName, attributeValue]) => (
                                  <span className="filter_heading filterHeadingContainer">
                                    <h3
                                      className={`h3FilterHeading h3FilterHeading-${index}`}
                                    >
                                      <span className="arrow">
                                        {arrowDirections[index] === "side" ? (
                                          <DownArrow className="arrow arrowSize" />
                                        ) : (
                                          <SideArrow className="arrow arrowSize" />
                                        )}
                                      </span>
                                      <span className="filterHeadingTitle">
                                        {attributeName}
                                      </span>
                                      {selectedCount[index] > 0 && (
                                        <span className="selectedCount">
                                          {selectedCount[index]}
                                        </span>
                                      )}
                                    </h3>
                                  </span>
                                )
                              )}
                            </div>
                          </dt>
                          {filterIsOpen[index] && (
                            <dd>
                              <div className="mutliSelect">
                                <ul>
                                  {/* Check if the length is 10 we want to display a search */}
                                  {Object.keys(attribute).flatMap(
                                    (attributeName) =>
                                      Object.keys(attribute[attributeName])
                                        .length >= 10 ? (
                                          loadFilterData(attribute, attributeID, attributeName)
                                      ) : (
                                        Object.keys(
                                          attribute[attributeName]
                                        ).map((value) => (
                                          <li key={value}>
                                            <label className="side_filter_item">
                                              <h6>
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"
                                                  onChange={() =>
                                                    handleOptionClick(
                                                      value,
                                                      attributeID,
                                                      attribute[attributeName]
                                                    )
                                                  }
                                                  checked={
                                                    selectedFilters[
                                                      attributeID
                                                    ] &&
                                                    selectedFilters[
                                                      attributeID
                                                    ][value] &&
                                                    selectedFilters[
                                                      attributeID
                                                    ][value].length > 0
                                                  }
                                                />{" "}
                                                {value}
                                              </h6>
                                            </label>
                                          </li>
                                        ))
                                      )
                                  )}
                                </ul>
                              </div>
                            </dd>
                          )}
                        </dl>
                      )
                    )
                  ) : (
                    <div className="attributeMessage">{attributeMessage}</div>
                  )}
                </>
              )}
            </div>
            <div className="clearFilter">
              <a onClick={() => handleClearFilter()}>Clear Filter</a>
            </div>
          </div>
        )}
      </div>
      {/* ===========================Filter section ==================================*/}
      <div id="side_bar_display_arrow">
        <SidebarSideArrow onClick={() => handleSidebarClick(!sidebarVisible)} />
      </div>
    </div>
  );
};

export default Sidebar;