import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BulkIcon } from "../../assets/images/icons/gear-solid-white.svg";
import { ReactComponent as BordsIcon } from "../../assets/images/icons/images-solid.svg";
import { ReactComponent as MoonIcon } from "../../assets/images/icons/moon-solid.svg";
import { ReactComponent as NavbarToggler } from "../../assets/images/icons/navbar-toggler.svg";
import { ReactComponent as ProfileIcon } from "../../assets/images/icons/profile_icon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/images/icons/right-from-bracket-solid.svg";
import { ReactComponent as SunIcon } from "../../assets/images/icons/sun-solid.svg";
import { ReactComponent as UploadIcon } from "../../assets/images/icons/upload.svg";
import { ReactComponent as CreateIcon } from "../../assets/images/icons/circlePlus.svg";
import { ReactComponent as EditBoardName } from "../../assets/images/icons/pencil.svg";
import MDICircle from "../../assets/images/logo/MDI_square.jpg";
import MDILogo from "../../assets/images/logo/mdi-logo-final.webp";
import { default as BulkMenu } from "../../components/BulkMenu/bulkMenu.component";
import { AllBoards, BoardType, ClientType } from "../../types";
import "./header.scss";
import { useParams } from "react-router-dom";
import OpenBoardCreationModal from "../../components/Boards/boardCreation.component";
import { FilteredBoards } from "../../components/Photos/photosContainer.component";
import { BoardContext } from "../../context/BoardsContext";
import { boardUpdate, boardDelete } from "../../models/board.model";
import localforage from "localforage";
import Swal from "sweetalert2";
import {
  updateClientDetails,
  fetchAllClients,
} from "../../models/client.model";
import { isArray } from "util";

export type HeaderProps = {
  onThemeChange: () => void;
  parsedClientID?: string | null;
  showBulk?: boolean;
  setShowBulk?: (showBulk: boolean) => void;
  selectedImageIds?: string[];
  setSelectedImageIds?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedBulkImageIds?: string[];
  setBulkSelectedImageIds?: (imageIds: string[]) => void;
  boardId?: string | null;
  // allBoards?: AllBoards | null;
  setAllBoards?: React.Dispatch<React.SetStateAction<AllBoards[]>>;
  selectType: "board" | "client" | "dashboard" | "search" | "keyword" | "none";
};

const Header: React.FC<HeaderProps> = ({
  onThemeChange,
  showBulk,
  selectedBulkImageIds,
  setBulkSelectedImageIds,
  selectedImageIds,
  setSelectedImageIds,
  setShowBulk,
  boardId,
  parsedClientID,
  selectType,
}) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isBulkMenuVisible, setBulkMenuVisible] = useState(false);
  const [theme, setTheme] = useState(localStorage.getItem("theme"));
  const [selectedClient, setSelectedClient] = useState<ClientType | null>(null);
  const [selectedName, setSelectedName] = useState<string>("");
  // const [clientName, setClientName] = useState<string>("");

  const boards = useContext(BoardContext);
  const [allBoards, setAllBoards] = useState<AllBoards[]>([]);

  const { clientID } = useParams<{ clientID: string }>();
  const [allClients, setAllClients] = useState<ClientType[]>();

  const nav = useNavigate();

  const [loadingBoards, setLoadingBoards] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string | null>(null);

  // State to manage whether the scrolling-text class should be applied
  const [isScrolling, setIsScrolling] = useState(false);
  // Event handler to enable scrolling
  const enableScrolling = () => {
    console.log("Scrolling enabled");
    // setTimeout(() => setIsScrolling(true), 400);
    setIsScrolling(true);
  };
  // Event handler to disable scrolling
  const disableScrolling = () => {
    console.log("Scrolling disabled");
    // setTimeout(() => setIsScrolling(false), 400);
    setIsScrolling(false);
  };

  // Used to show icon in the top right of header
  useEffect(() => {
    const fetchClient = async () => {
      try {
        let allClients: string | null = await localforage.getItem<string>(
          "allClients"
        );
        if (allClients) {
          try {
            const clientsResponse = JSON.parse(allClients);
            if (Array.isArray(clientsResponse)) {
              const client: ClientType = clientsResponse.find(
                (client: ClientType) =>
                  client.client_id.toString() === parsedClientID
              );
              setSelectedClient(client || null);
              setAllClients(clientsResponse);
            } else {
              console.error("Failed to fetch or parse clients:", allClients);
            }
          } catch (error) {
            console.error("Failed to parse clients:", allClients);
            setSelectedClient(null);
          }
        } else {
          const getClients = await fetchAllClients();
          if (Array.isArray(getClients)) {
            localforage.setItem("allClients", JSON.stringify(getClients));
            setAllClients(getClients);
          }
        }
      } catch (error) {
        console.error("Failed to fetch or parse clients:", error);
        setSelectedClient(null);
      }
    };
    fetchClient();
  }, []);

  // Show boardCreationModal
  const [showBoardCreationModal, setShowBoardCreationModal] = useState(false);
  // Function that sets the setShowBoardCreationModal to false
  const closeBoardCreationModal = () => {
    setShowBoardCreationModal(false);
  };

  const updateBoardName = async (boardId: string, newName: string) => {
    const boardUpdateResults = await boardUpdate(boardId, newName);
    if (typeof boardUpdateResults === "string") {
      Swal.fire({
        title: "Failed to Update Board",
        text: boardUpdateResults,
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      await localforage.removeItem("allBoards");
      Swal.fire({
        title: "Board Name Updated",
        icon: "success",
        showCloseButton: true,
      });
      //changing allboards context to update the board name
      if (boards && boards.allBoards) {
        const allBoards = boards.allBoards;
        const boardIndex = allBoards.findIndex(
          (board) => board.id.toString() === boardId
        );
        allBoards[boardIndex].title = newName;
        setAllBoards(allBoards);
      }
      // Re-Load Current Window
      window.location.reload();
    }
  };

  const updateClientName = async (clientID: string, newName: string) => {
    const clientUpdateResults = await updateClientDetails(clientID, newName);
    if (clientUpdateResults !== "success") {
      Swal.fire({
        title: "Failed to Update Project",
        text: clientUpdateResults,
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      //update local storage on client name change
      setSelectedClient((prev) => {
        if (prev && prev.client_id.toString() === clientID) {
          // return { ...prev, name: newName };
          prev.name = newName;
          return prev;
        }
        return prev;
      });
      // setClientName(newName);
      localforage.removeItem("allClients");

      Swal.fire({
        title: "Project Name Updated",
        icon: "success",
        showConfirmButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("SELECTED CLIENT", selectedClient);
          nav(`/client/${clientID}`);
        }
      });
    }
  };

  const deleteBoardControl = async (boardId: string) => {
    const boardDeleteResults = await boardDelete(boardId);
    if (boardDeleteResults.rStatus === "error") {
      Swal.fire({
        title: "Failed to Delete Board",
        text: boardDeleteResults.rMessage,
        icon: "error",
        showConfirmButton: false,
        showCloseButton: true,
      });
    } else {
      await localforage.removeItem("allBoards");

      //changing allboards context to update the board name
      if (boards && boards.allBoards) {
        const allBoards = boards.allBoards.filter(
          (board) => board.id.toString() !== boardId
        );
        setAllBoards(allBoards);

        Swal.fire({
          title: "Board Deleted",
          icon: "success",
          showCloseButton: true,
        });

        // Re-Load Boards Data before return to DashBoard
        boards.getBoardData(true);
        // Navigate to dashboard after successful deletion
        nav("/dashboard");
      }
    }
  };

  //Display FIRST Board/Project Edit Popup Menu - Delete and Update Name
  const editDetails = () => {
    Swal.fire({
      title: selectType === "client" ? "Edit Project" : "Edit Board",
      html: `
    <div style="display: flex; flex-wrap: wrap; align-items:center;">
      <label for="swal-input1" style="margin:10px">Name</label>
      <input id="swal-input1" class="swal2-input" value="${
        selectType === "client" ? selectedClient?.name : selectedName
      }" style="margin:0px">
    </div>
    ${
      selectType === "client"
        ? ""
        : `<div style="display: flex; flex-wrap: wrap; align-items: center;">
      <label for="swal-input2" 
        style="margin:10px !important; margin-top:5px ">Client</label>
      <input id="swal-input2" class="swal2-input" disabled placeholder=${selectedClient?.name} 
        style="margin:0 !important; margin-top:5px !important">
    </div>`
    }`,
      confirmButtonText: "Save",
      confirmButtonColor: "#273361",
      denyButtonText: "Delete",
      denyButtonColor: "#BB2D3B",
      reverseButtons: true,
      showCloseButton: true,
      showDenyButton: selectType === "client" ? false : true,
      preConfirm: () => {
        const specialCharsPattern = /[()&@$|%~]/;

        const newName = (
          document.getElementById("swal-input1") as HTMLInputElement
        ).value;

        const client = (
          document.getElementById("swal-input2") as HTMLInputElement
        )?.value;

        if (!newName) {
          Swal.showValidationMessage(
            `Please enter a ${
              selectType === "client" ? "project" : "board"
            } name`
          );
          return;
        } else if (newName.trim() === "") {
          Swal.showValidationMessage(
            `${
              selectType === "client" ? "Project" : "Board"
            } name cannot be blank`
          );
          return;
        } else if (newName.length < 3) {
          Swal.showValidationMessage(
            `${
              selectType === "client" ? "Project" : "Board"
            } name cannot be shorter than 3 characters`
          );
          return;
        } else if (newName.length > 64) {
          Swal.showValidationMessage(
            `${
              selectType === "client" ? "Project" : "Board"
            } name cannot be longer than 64 characters`
          );
          return;
        } else if (
          selectType === "client"
            ? allClients?.find((client) => client.name === newName)
            : boards?.allBoards?.find((board) => board.title === newName)
        ) {
          Swal.showValidationMessage(
            `${
              selectType === "client" ? "Project" : "Board"
            } name already exists`
          );
          return;
        } else {
          if (specialCharsPattern.test(newName)) {
            Swal.showValidationMessage(
              `${
                selectType === "client" ? "Project" : "Board"
              } Name cannot contain special characters`
            );
            return;
          }
        }
        return { newName, type: selectType === "client" ? "client" : "board" };
      },
    }).then((result): void => {
      if (result.isDenied) {
        confirmBoardDeletion();
      } else if (result.isConfirmed) {
        Swal.fire({
          title: "Saving New Name",
          text:
            "Please wait while the " +
            (selectType === "client" ? "project" : "board") +
            " name is being saved",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        if (result.value.type === "client") {
          if (!parsedClientID) {
            console.log("No client ID found");
            return;
          }
          updateClientName(parsedClientID, result.value.newName);
        } else {
          if (boardId) {
            updateBoardName(boardId, result.value.newName);
          }
        }
      }
    });
  };

  //Display SECOND Board Edit Popup - Confirm Board Deletion
  const confirmBoardDeletion = () => {
    Swal.fire({
      title: "Are You Sure?",
      // html: `
      // <div>
      // <label id = "boardNameField">Please type 'DELETE' to confirm: </label>
      // <input id="confirm_delete" class="swal2-input" autocomplete="off">
      // </div>
      // `,
      text: "Please type 'DELETE' to confirm:",
      input: "text",
      inputAttributes: {
        autocomplete: "off",
      },
      // BB2D3B
      confirmButtonText: "Confirm Deletion",
      confirmButtonColor: "#BB2D3B",
      showCloseButton: true,
      cancelButtonText: "Cancel",
      showCancelButton: true,
      icon: "warning",
      iconColor: "#BB2D3B",

      showConfirmButton: true,
      inputValidator(value) {
        if (!value) {
          return "Please type 'DELETE' to confirm";
        }
        if (value !== "DELETE") {
          return "Please type 'DELETE' to confirm";
        }
      },
      // didClose() {
      //   setModalOpen(false);
      // },
    }).then((result): void => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleting Board",
          text: "Please wait while the board is being deleted",
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        if (boardId) {
          deleteBoardControl(boardId);
        }
      }

      // setModalOpen(false);
    });
  };

  // Retrieve Client Name for Current Board
  useEffect(() => {
    const loadClients = async () => {
      const localClients = await localforage.getItem("allClients");

      if (typeof localClients === "string") {
        try {
          const jsonData = JSON.parse(localClients);
          jsonData["clients"].forEach((client: ClientType) => {
            if (client.client_id?.toString() === parsedClientID) {
              // setClientName(client.name);
              setSelectedClient(client);
              return;
            }
          });
        } catch (error) {
          console.error("Error parsing client data", error);
        }
      }
    };
    loadClients();
  }, []);

  // If on client, show client icon, else show MDI icon
  const backgroundImageStyle =
    selectedClient && selectedClient.logo
      ? `url(${selectedClient.logo})`
      : `url(${MDICircle})`;

  const [actionMessage, setActionMessage] = useState("");
  // const showReImportButton = window.location.pathname.includes("/client");

  const handleMessage = (message: string) => {
    setActionMessage(message);
    setTimeout(() => {
      setActionMessage("");
    }, 2000);
  };

  /*--------------------------------------*/
  /*-----------Fetch Board Data-----------*/
  /*--------------------------------------*/
  //This
  // useEffect(() => {
  //   const fetchBoards = async () => {
  //     setLoadingBoards(true);
  //     try {
  //       const jsonData = await getAllBoards();
  //       // if jsonData is a string, then there was an error
  //       if (typeof jsonData === "string") {
  //         console.log("error");
  //         setBoards([]);
  //         if (setAllBoards) {
  //           setAllBoards([]);
  //         }
  //         setLoadingError(jsonData);
  //         setLoadingBoards(false);
  //       } else {
  //         //setBoards(jsonData);
  //         if (setAllBoards && typeof jsonData !== "string") {
  //           setAllBoards(jsonData);
  //         }
  //         console.log("success", jsonData);
  //         setLoadingError(null);
  //         setLoadingBoards(false);
  //       }
  //     } catch (error) {
  //       console.error("An error occurred while fetching data:", error);
  //       setBoards([]);
  //       setLoadingError("An error occurred while fetching data");
  //       setLoadingBoards(false);
  //     }
  //   };

  //   fetchBoards();
  // }, []);

  const getFilteredBoards = async () => {};

  // GET BOARDS BY CLIENT ID, FILTER OUT THE CURRENT BOARD
  useEffect(() => {
    if (
      boards &&
      boards.isBoardsLoaded &&
      boards.allBoards &&
      boards.allBoards.length > 0
    ) {
      if (parsedClientID) {
        const clientBoards = boards.allBoards.filter(
          (board: FilteredBoards) =>
            board.clientID.toString() === parsedClientID &&
            board.id.toString() !== boardId &&
            board.client !== true
        );

        setAllBoards(clientBoards);
      } else {
        console.log("No client ID");
      }
    }
    if (boardId && boards?.allBoards && boards?.allBoards.length > 0) {
      const board = boards?.allBoards.find(
        (board: FilteredBoards) => board.id.toString() === boardId
      );
      if (board) {
        setSelectedName(board.title);
      }
    }
  }, [boards, boardId]); // Add boardId to the dependency array

  /*--------------------------------------*/
  /*--------------Functions-----S----------*/
  /*--------------------------------------*/
  //-------------Navigation to boards-----------------//
  const navigate = useNavigate();
  const boardClickHandler = (boardId: string, e: React.MouseEvent) => {
    e.preventDefault();
    handleNavbarDropdownClick(false);
    if (e.button === 2) {
      const win = window.open(`${parsedClientID}/board/${boardId}`, "_blank");
      win?.blur();
      window.focus();
    } else {
      console.log("BoardClicked: " + boardId);
      navigate(`${parsedClientID}/board/` + boardId);
    }
  };

  // This is the on hover handler for the profile icon
  const profileHoverHandler = () => {
    setMenuVisible(true);

    if (isBulkMenuVisible) {
      setBulkMenuVisible(false);
    }
  };

  // When you hover out the profile menu this will happen
  const profileMouseOutHandler = () => {
    setMenuVisible(false);
  };
  //-------------Bulk Action Handlers-----------------//
  const bulkHoverHandler = () => {
    setBulkMenuVisible(true);
  };
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  function bulkMouseOutHandler(isHovered: boolean) {
    // Clear the previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Start a new timeout
    timeoutRef.current = setTimeout(() => {
      if (!isHovered) {
        setBulkMenuVisible(false);
      }
    }, 2000);
  }

  // Clear the timeout when the component unmounts
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const bulkMenuClickHandler = () => {
    setBulkMenuVisible(!isBulkMenuVisible);
    setMenuVisible(false);
  };

  // View all boards handler
  const viewBoards = () => {
    console.log("ViewBoards Clicked");
    navigate("/all-boards");
  };

  // View profile handler
  const viewProfile = () => {
    console.log("ViewProfile Clicked");
    navigate("/profile");
  };

  // Logout handler
  const logout = async () => {
    // Ensure that agreementAcknowledged is not cleared
    const fieldToKeep = "agreementAcknowledged";
    // Remove all keys that are not 'agreementAcknowledged'
    await localforage.iterate(async (value, key, iterationNumber) => {
      if (key !== fieldToKeep) {
        await localforage.removeItem(key);
      }
    });

    console.log("Logout Clicked");
    localStorage.clear();
    window.location.reload();
    navigate("/login");
  };

  const themeClickHandler = () => {
    console.log("Theme Clicked");
    onThemeChange(); // Call the function received from parent
    setTheme((prevTheme) =>
      prevTheme === "lightmode" ? "darkmode" : "lightmode"
    );
  };
  // This is the on click handler for the mdi logo
  const logoClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    if (e.button === 2) {
      const win = window.open(`/dashboard`, "_blank");
      win?.blur();
      window.focus();
    } else {
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, [localStorage.getItem("theme")]);

  // Clearing selections, this is used in the bulk menu
  const clearSelection = () => {
    if (setSelectedImageIds && setBulkSelectedImageIds) {
      setSelectedImageIds([]);
      setBulkSelectedImageIds([]);

      // Hides bulk menu button
      if (setShowBulk) {
        setShowBulk(false);
      }
      // Hides bulk menu
      setBulkMenuVisible(false);
    }
  };
  const [navbarDropdown, setNavbarDropdown] = useState(false);

  const handleNavbarDropdownClick = (boolSideBar: boolean) => {
    setNavbarDropdown(boolSideBar);
  };

  // const handleReImport = async () => {
  //   console.log("Re-importing data...");
  //   if (!client_Id) {
  //     console.error("No client ID provided");
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error fetching data",
  //       text: "No client ID provided",
  //     });
  //     return;
  //   }

  //   try {
  //     const data = await fetchLatestData(client_Id);

  //     if (typeof data === "string") {
  //       console.error("Error fetching data:", data);
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error fetching data",
  //         text: data,
  //       });
  //       return;
  //     } else {
  //     }
  //     console.log("Data fetched successfully:", data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  /*--------------------------------------*/

  return (
    // Code for the header nav menu
    // We are using uk-grid to create a grid layout
    <div
      id="main_header"
      className={`uk-grid ${navbarDropdown ? "navbar_dropdown_shown" : ""}`}
    >
      {/* THE FIRST GRID: */}
      <div className="navmenulogo-bg uk-text-center uk-width-1-6">
        <img
          className="logoSize uk-padding-small"
          src={MDILogo}
          alt="MDI Logo"
          onMouseDown={(e) => logoClickHandler(e)}
        />
      </div>

      {/* Name */}
      <div className="navmenuboard-bg uk-flex-inline uk-flex-center uk-width-expand navMenuBoards_container">
        <ul className="boardItems">
          <li className="uk-padding-small navMenuBoards_board animationEffect">
            <span
              className="linkToBoards"
              onMouseEnter={enableScrolling}
              onMouseLeave={disableScrolling}
            >
              <span
                className={isScrolling ? "scrolling-text" : ""}
                style={{ color: "#fff" }}
              >
                {selectType === "client"
                  ? selectedClient?.name
                  : selectType === "board"
                  ? selectedName
                  : ""}
              </span>
            </span>
          </li>
        </ul>
      </div>

      {/* Bulk Action   */}
      {showBulk && (
        <div className="bulkIconOuter_main">
          <div className="bulkIconOuter">
            <div
              className="bulkIconContainer"
              onMouseEnter={() => bulkMouseOutHandler(true)}
              onMouseLeave={() => bulkMouseOutHandler(false)}
              onClick={bulkMenuClickHandler}
            >
              <span className="textContent ">
                ( {selectedBulkImageIds?.length + " "} )
                <span className="textContent bulkActionText">Bulk Actions</span>
              </span>
              <BulkIcon className="profileSize bulkIconCog" />
            </div>
          </div>
          {isBulkMenuVisible && (
            <BulkMenu
              onThemeChange={onThemeChange}
              clearSelection={clearSelection}
              setBulkSelectedImageIds={setBulkSelectedImageIds}
              selectedBulkImageIds={selectedBulkImageIds}
              selectedImageIds={selectedImageIds}
              setSelectedImageIds={setSelectedImageIds}
              setShowBulk={setShowBulk}
              boardId={boardId}
              handleMessage={handleMessage}
              bulkMenuMouseOutHandler={bulkMouseOutHandler}
              clientID={
                parsedClientID ? parsedClientID : clientID ? clientID : ""
              }
              allBoards={allBoards}
              selectType={selectType}
            ></BulkMenu>
          )}
        </div>
      )}
      {/* THE THIRD GRID: */}
      <div
        className="navmenuprofile-bg uk-flex uk-flex-right uk-width-1-4"
        onMouseLeave={() => {
          profileMouseOutHandler();
          bulkMouseOutHandler(false);
        }}
      >
        {/*  ------- -------------- --------------- ------------------*/}
        {/* Edit Board or Client (Project) Name Button*/}
        {((selectType === "board" && clientID) ||
          (selectType === "client" && parsedClientID)) && (
          <div className="editBoardContainer">
            <EditBoardName
              className="boardNameEditBtn uk-padding-small"
              onClick={() => editDetails()}
              data-toggle="tooltip"
              title={
                selectType === "client" ? "Project Details" : "Board Details"
              }
            />
          </div>
        )}
        {/*  ------- -------------- --------------- ------------------*/}
        {parsedClientID && (
          <div className="uploadContainer">
            <UploadIcon
              className="profileUpload upload uk-padding-small"
              onClick={() => {
                navigate(`/upload/${parsedClientID}`);
              }}
              data-toggle="tooltip"
              title="Upload"
            />
          </div>
        )}

        <div className="createBoardContainer">
          <CreateIcon
            className="profileSize uk-padding-small"
            onClick={() => {
              setShowBoardCreationModal(true);
            }}
            data-toggle="tooltip"
            title="Create Board"
          />
        </div>
        <div
          onMouseLeave={() => {
            profileMouseOutHandler();
          }}
          className="profileContainer"
        >
          {/* <img
            className="profileSize uk-padding-small"
            src={ProfileIcon}
            onMouseEnter={profileHoverHandler}
            alt="Profile"
          /> */}
          <ProfileIcon
            onMouseEnter={profileHoverHandler}
            className="profileSize uk-padding-small"
          />
          {isMenuVisible && (
            <div className="profileMenu">
              {/* TODO: update userName */}
              {/* <div className="username">JOHN TEST</div> */}
              {/* <div className="profileContent" onClick={viewProfile}>
                <BordsIcon className="iconContent" />
                <span className="textContent">Profile</span>
              </div> */}
              <div className="profileContent" onClick={viewBoards}>
                <BordsIcon className="iconContent" />
                <span className="textContent">View All Boards</span>
              </div>
              <div className="profileContent" onClick={themeClickHandler}>
                {theme === "lightmode" ? (
                  <MoonIcon className="iconContent darkText" />
                ) : (
                  <SunIcon className="iconContent lightText" />
                )}
                {theme === "lightmode" ? "Dark Mode" : "Light Mode"}
              </div>
              <span className="profileContent" onClick={logout}>
                <LogOutIcon className="iconContent" />
                <span className="textContent">Logout</span>
              </span>
            </div>
          )}
        </div>
        {/* Clients  */}
        <div
          className="circle_logo_container uk-padding-small"
          onClick={() => {
            navigate("/all-clients");
          }}
        >
          <button
            className={`logo_button clickable`}
            onClick={() => console.log("clicked")}
            uk-tooltip="title: Projects ; pos: left"
            style={{
              backgroundImage: backgroundImageStyle,
            }}
          ></button>
        </div>
      </div>

      {showBoardCreationModal && (
        <OpenBoardCreationModal setModalOpen={closeBoardCreationModal} />
      )}

      {/* Nav Toggler */}
      <div
        id="nav_toggler"
        className={`${navbarDropdown ? "shown" : "hidden"}`}
      >
        <NavbarToggler
          onClick={() => handleNavbarDropdownClick(!navbarDropdown)}
        />
      </div>
      {actionMessage !== "" ? (
        <div className="image_action_status_message">{actionMessage}</div>
      ) : null}
    </div>
  );
};

export default Header;
