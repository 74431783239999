import React, { useEffect, useState } from "react";
import boardsData from "../../assets/data/boardsData.json";
import jsonImagesData from "../../assets/data/photos.json";
import { AllBoards, BoardType, ImageType, ManageAttributes } from "../../types";
import { useAttributeContext } from "../../context/UserAttributeContext";
import { assignAttributes } from "../../models/wsc.model";
import "./bulkMenu.scss";
import {
  addImagesToBoard,
  addKeyword,
  copyImagesToBoard,
  downloadBulkImages,
  moveImagesToBoard,
  removeImagesFromBoard,
  fetchCsvDocument,
} from "../../models/images.model";
import { copyImages } from "../../models/board.model";
import localforage from "localforage";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

type BulkMenuProps = {
  clearSelection: () => void;
  onThemeChange: () => void;
  //   showBulk?: boolean;
  setShowBulk?: (showBulk: boolean) => void;
  selectedImageIds?: string[];
  setSelectedImageIds?: React.Dispatch<React.SetStateAction<string[]>>;
  selectedBulkImageIds?: string[];
  setBulkSelectedImageIds?: (imageIds: string[]) => void;
  boardId?: string | null;
  handleMessage: (message: string) => void;
  bulkMenuMouseOutHandler: (isHovered: boolean) => void;
  clientID: string;
  allBoards: AllBoards[];
  selectType: "board" | "client" | "dashboard" | "search" | "keyword" | "none";
};

const images: ImageType[] = jsonImagesData.images;

const BulkMenu: React.FC<BulkMenuProps> = ({
  clearSelection,
  selectedBulkImageIds,
  setBulkSelectedImageIds,
  selectedImageIds,
  setSelectedImageIds,
  boardId,
  handleMessage,
  bulkMenuMouseOutHandler,
  clientID,
  allBoards,
  selectType,
}) => {
  // States:

  // Initialize boards state

  // Use state for bulk icon click handler
  const [isBulkMenuVisible, setBulkMenuVisible] = useState(false);

  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  // Use state for move menu visibility
  const [isMoveMenuVisible, setMoveMenuVisible] = useState(false);

  // Use state for move confirmation menu
  const [moveMenuConfirmation, setMoveMenuConfirmation] = useState(false);

  // Use state for delete menu visibility
  const [isDeleteMenuVisible, setDeleteMenuVisible] = useState(false);

  // Use state for copy menu visibility
  const [isCopyMenuVisible, setCopyMenuVisible] = useState(false);

  // Use state for copy confirmation menu
  const [copyMenuConfirmation, setCopyMenuConfirmation] = useState(false);

  const [addKeywordClicked, setAddKeywordClicked] = useState(false);

  // Use state for delete confirmation menu
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
    useState(false);

  // Check for delete input
  const [deleteInput, setDeleteInput] = useState("");

  // User-Attributes Context
  const userAttributeContext = useAttributeContext();
  // Allow navigation
  const navigate = useNavigate();

  // // Checks if the board is active or not
  // useEffect(() => {
  //   // Set the initial boards state with the active property
  //   const initialBoards: BoardType[] = boardsData.boards.map((board) => ({
  //     ...board,
  //     active: boardId !== null && boardId === board.id ? true : false,
  //   }));
  //   setBoards(initialBoards);
  // }, [boardId]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedBoard, setSelectedBoard] = useState("");

  const bulkHoverHandler = () => {
    setBulkMenuVisible(true);
  };

  const bulkMouseOutHandler = () => {
    setBulkMenuVisible(false);
    //setMoveMenuVisible(false);
    setDeleteMenuVisible(false);
    setMoveMenuConfirmation(false);
    setAddKeywordClicked(false);
  };

  useEffect(() => {
    setTheme(localStorage.getItem("theme"));
  }, [localStorage.getItem("theme")]);

  // Copying images to board
  const handleCopyImages = async (selectedBoardID: string) => {
    if (!selectedBulkImageIds || selectedBulkImageIds.length === 0) {
      handleMessage("Please select an image to copy");
      return;
    }

    // Adding images to board from client view
    if (selectType === "board") {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to copy images to another board",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showLoaderOnConfirm: true, // Add this line
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Add images to client
          setIsLoading(true);

          const copyResponse = await copyImagesToBoard({
            imagesToCopy: selectedBulkImageIds,
            currentBoardID: boardId || "",
            nextBoardID: selectedBoardID,
          });

          if (copyResponse.rStatus === "success") {
            Swal.fire(
              "Success",
              "Image(s) copied to board successfully",
              "success"
            );
            localforage.removeItem(`ds-board-${selectedBoardID}-current`);
            localforage.removeItem(
              `filters-board-${clientID}-${selectedBoardID}-current`
            );
            clearSelection();
          } else {
            Swal.fire(
              "Error",
              "Error copying image(s) to board: " + copyResponse.rMessage,
              "error"
            );
          }

          setIsLoading(false);
          setCopyMenuConfirmation(false);
        }
      });
    }
  };

  // Adding images to board
  const handleAddImages = async (selectedBoardID: string) => {
    if (!selectedBulkImageIds || selectedBulkImageIds.length === 0) {
      Swal.fire("Error", "Please select an image to copy", "error");
      return;
    }

    // Adding images to board from client view
    if (selectType === "client") {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to add images to a board",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log(clientID, selectedBoard, selectedBulkImageIds);
          setIsLoading(true);
          const addImagesToBoardResponse = await addImagesToBoard({
            clientID: clientID,
            boardID: selectedBoardID,
            imagesToAdd: selectedBulkImageIds,
          });

          if (addImagesToBoardResponse.rStatus === "success") {
            Swal.fire(
              "Success",
              "Image(s) added to board successfully",
              "success"
            );
            try {
              localforage.removeItem(`ds-board-${selectedBoardID}-current`);
              localforage.removeItem(
                `filters-board-${clientID}-${selectedBoardID}-current`
              );
            } catch (error) {
              console.log("error removing from local forage", error);
            }

            clearSelection();
          } else if (addImagesToBoardResponse.rStatus === "exists") {
            Swal.fire({
              icon: "info",
              title: "Images already pinned",
              text: `Images is already pinned to the board`,
            });
          } else {
            Swal.fire(
              "Error",
              "Error adding image(s) to board: " +
                addImagesToBoardResponse.rMessage,
              "error"
            );
          }

          setIsLoading(false);
          setCopyMenuConfirmation(false);
        }
      });
    }
  };

  const handleMoveImages = async (selectedBoardID: string) => {
    if (!selectedBulkImageIds || selectedBulkImageIds.length === 0) {
      Swal.fire("Error", "Please select an image to copy", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You are about to move images to another board",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      showLoaderOnConfirm: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);

        const moveResponse = await moveImagesToBoard({
          imagesToMove: selectedBulkImageIds,
          currentBoardID: boardId || "",
          nextBoardID: selectedBoardID,
        });

        if (moveResponse.rStatus === "success") {
          Swal.fire(
            "Success",
            "Image(s) moved to board successfully",
            "success"
          );
          try {
            localforage.removeItem(`ds-board-${boardId}-current`);
            localforage.removeItem(`ds-board-${selectedBoardID}-current`);
          } catch (error) {
            console.log("error removing from local forage", error);
          }
          window.location.reload();
          clearSelection();
        } else {
          Swal.fire(
            "Error",
            "Error moving image(s) to board: " + moveResponse.rMessage,
            "error"
          );
        }

        setIsLoading(false);
        setSelectedBoard("");
        setMoveMenuConfirmation(false);
        clearSelection();
      }
    });
  };

  // Delete after confirmation
  const handleDeleteMessage = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: 'Please type "REMOVE" to confirm',
      input: "text",
      icon: "warning",
      iconColor: "#BB2D3B",
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: "#BB2D3B",
      cancelButtonColor: "#3085d6",

      preConfirm: (deleteInput) => {
        if (deleteInput !== "REMOVE") {
          Swal.showValidationMessage('You need to type "REMOVE" to confirm');
        } else {
          if (
            selectedBulkImageIds &&
            selectedBulkImageIds.length > 0 &&
            boardId
          ) {
            Swal.fire({
              title: selectType === "client" ? "Deleting..." : "Removing...",
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading();
              },
            });
            return removeImagesFromBoard({
              boardID: boardId,
              imagesToRemove: selectedBulkImageIds,
            })
              .then(async (removeImageResponse) => {
                if (removeImageResponse.rStatus === "success") {
                  Swal.fire(
                    "Success",
                    "Image(s) removed successfully",
                    "success"
                  );
                  clearSelection();
                  try {
                    console.log(boardId, clientID);
                    console.log(`ds-board-${boardId}-current`);
                    console.log(`filters-board-${clientID}-${boardId}-current`);
                    await localforage.removeItem(`ds-board-${boardId}-current`);
                    await localforage.removeItem(
                      `filters-board-${clientID}-${boardId}-current`
                    );
                  } catch (error) {
                    console.log("error removing from local forage", error);
                  }
                  window.location.reload();
                } else {
                  Swal.showValidationMessage("Error removing image(s)");
                }
              })
              .catch((error) => {
                Swal.showValidationMessage(`Request failed: ${error}`);
              });
          } else {
            Swal.showValidationMessage("Please select an image to remove");
          }
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    setIsLoading(false);
    setDeleteInput("");
    setDeleteConfirmationVisible(false);
    clearSelection();
  };

  const keyWordAlert = async () => {
    Swal.fire({
      title: "Add one keyword",
      input: "text",
      inputPlaceholder: "Keyword Name",
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
        if (value.length > 20) {
          return "Keyword should be less than 20 characters";
        }
        if (value.length < 2) {
          return "Keyword should be more than 2 characters";
        }
        let regex = /[()&@*$|%~]/;
        if (regex.test(value)) {
          return "Keyword should not contain special characters";
        }
      },
      showCancelButton: true,
      confirmButtonText: "Add",
      showLoaderOnConfirm: true,
      preConfirm: (keyword) => {
        if (!selectedBulkImageIds || selectedBulkImageIds.length === 0) {
          return Promise.reject("Please select an image to add a keyword to");
        }

        return addKeyword({ clientID, keyword, imageIDs: selectedBulkImageIds })
          .then((response) => {
            if (response.rStatus === "success") {
              Swal.fire("Success", "Keyword added successfully.", "success");
            } else {
              Swal.showValidationMessage(
                `Error adding keyword: ${response.rMessage}`
              );
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  // Multiple Image Download Handler
  const handleBulkDownload = async () => {
    if (!selectedBulkImageIds || selectedBulkImageIds.length === 0) {
      return;
    }
    //For now we are only allowing 100 downloads
    if (selectedBulkImageIds.length > 100) {
      Swal.fire({
        title: "Warning",
        text: "You can only download 100 images at a time",
        icon: "warning",
      });
      return;
    }
    Swal.fire({
      title: "Bulk Download",
      html: "Please ensure you have allow for multiple downloads in your browser settings",
      icon: "info",
      confirmButtonText: "Continue",
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        //Swal loader
        Swal.fire({
          title: "Downloading images",
          html: "Please wait while we download your images",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });
        const response = await downloadBulkImages(
          clientID,
          selectedBulkImageIds
        );
        if (response === "success") {
          Swal.fire({
            title: "Success",
            text: "Your images are ready to be saved. If you have auto-download enabled, your images will be downloaded automatically",
            icon: "success",
          });
        } else {
          Swal.fire({
            title: "Error downloading images",
            text: response,
            icon: "error",
          });
        }
      }
    });
  };

  // const downloadPromises = selectedBulkImageIds.map(async (id, index) => {
  //   const selectedImage = images.find((image) => image.id === id);
  //   if (!selectedImage || !selectedImage.source) {
  //     throw new Error(
  //       `Image source missing for selected image at index ${index}`
  //     );
  //   }

  //   const response = await fetch(selectedImage.source);
  //   if (!response.ok) {
  //     throw new Error(`Failed to download image at index ${index}`);
  //   }

  //   const blob = await response.blob();
  //   const fileName = selectedImage.title || `image_${index}.jpg`;

  //   const url = window.URL.createObjectURL(blob);
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = fileName;
  //   document.body.appendChild(a);
  //   a.click();
  //   a.remove();
  //   window.URL.revokeObjectURL(url);
  // });

  // await Promise.all(downloadPromises);

  const moveMenuHandler = () => {
    if (moveMenuConfirmation) {
      // If move menu confirmation is already visible, close it
      setMoveMenuConfirmation(false);
    } else {
      // If move menu confirmation is not visible, open it and close other menus
      setMoveMenuVisible(true);
      setDeleteMenuVisible(false);
      setAddKeywordClicked(false);
      setDeleteConfirmationVisible(false);
      setCopyMenuConfirmation(false);
      setCopyMenuVisible(false);
    }

    if (isMoveMenuVisible) {
      setMoveMenuVisible(false);
    }
  };

  // Opens Keyword Menu, closes other menus
  const keywordMenuHandler = () => {
    // setAddKeywordClicked((prevVisible) => !prevVisible);
    setMoveMenuVisible(false); // Add this line to close the move menu when the keyword menu is opened
    setDeleteMenuVisible(false);
    setDeleteConfirmationVisible(false);
    setMoveMenuConfirmation(false);
    setCopyMenuConfirmation(false);
    setCopyMenuVisible(false);
  };

  const deleteMenuHandler = () => {
    setDeleteMenuVisible((prevVisible) => !prevVisible);
    if (deleteConfirmationVisible) {
      setDeleteConfirmationVisible(false);
    }

    if (isDeleteMenuVisible || deleteConfirmationVisible) {
      setDeleteMenuVisible(false);
    }

    setMoveMenuVisible(false); // Close the move menu when the delete menu is opened
    setAddKeywordClicked(false);
    setMoveMenuConfirmation(false);
    setCopyMenuConfirmation(false);
    setCopyMenuVisible(false);
  };

  const deleteMenuConfirmHandler = async () => {
    setDeleteMenuVisible(false);
    setDeleteConfirmationVisible(true);
    setCopyMenuVisible(false);
    setCopyMenuConfirmation(false);
    setCopyMenuVisible(false);
  };

  const copyMenuHandler = () => {
    if (copyMenuConfirmation) {
      setCopyMenuConfirmation(false);
    } else {
      setCopyMenuVisible(true);
      setDeleteMenuVisible(false);
      setDeleteConfirmationVisible(false);
      setAddKeywordClicked(false);
      setMoveMenuVisible(false);
    }
    if (isCopyMenuVisible) {
      setCopyMenuVisible(false);
    }
    // setMoveMenuVisible(false); // Add this line to close the move menu when the keyword menu is opened
  };

  // USER-ATTRIBUTE ASSIGNMENT
  const callAssignUserAttributes = () => {
    // Allow Attribute Assignment if User Attributes have been created for current client
    if (
      userAttributeContext.attributeData &&
      userAttributeContext.attributeData.length > 0
    ) {
      Swal.fire({
        title: "Attribute Assignment",
        html: `  
          <div id="attribute_assign_popup">
              <select id="selectedAttribute" class="swal2-input">
                <option value="" disabled selected>Available User Attributes</option>
                  ${userAttributeContext.attributeData.map(
                    (attribute) =>
                      `<option value="${attribute.id}">${attribute.name}</option>`
                  )}
              </select>
            <input id="attribute_value" class="swal2-input" placeholder="Enter a Value">
          </div>
            `,
        confirmButtonText: "Assign",
        confirmButtonColor: "#273361",
        showCloseButton: true,

        preConfirm: () => {
          const specialChars = ["(", ")", "&", "@", "$", "|", "%", "~"];

          const selectedAttribute = (
            document.getElementById("selectedAttribute") as HTMLInputElement
          ).value;
          const attributeValue = (
            document.getElementById("attribute_value") as HTMLInputElement
          ).value;

          if (!selectedAttribute) {
            Swal.showValidationMessage("Please select an attribute");
            return;
          }

          if (!attributeValue) {
            Swal.showValidationMessage("Please enter a value");
            return;
          } else if (attributeValue.trim() === "") {
            Swal.showValidationMessage("Value cannot be blank");
          } else if (attributeValue.length < 3) {
            Swal.showValidationMessage(
              "Value cannot be shorter than 3 characters"
            );
            return;
          } else if (attributeValue.length > 64) {
            Swal.showValidationMessage(
              "Value cannot be longer than 64 characters"
            );

            return;
          } else {
            specialChars.map((char) => {
              if (attributeValue.includes(char)) {
                Swal.showValidationMessage(
                  "Value cannot contain special characters"
                );
              }
            });
          }

          return { selectedAttribute, attributeValue };
        },
      }).then((result): void => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Assigning Values",
            text: "Please wait while the attribute is being assigned",
            allowOutsideClick: false,
            showConfirmButton: false,
            willOpen: () => {
              Swal.showLoading();
            },
          });
          assignUserAttributes(
            result.value.selectedAttribute,
            result.value.attributeValue
          );
        }
      });
    } else {
      // Popup Display if there are no user attributes
      Swal.fire({
        title: "No user attributes found",
        text: "Use the 'Manage Attributes' tab to create new user attributes.",
        icon: "info",
        iconColor: "#273361",
        confirmButtonText: "Go to Manage Attributes",
        showConfirmButton: true,
        showCloseButton: true,
      }).then((result): void => {
        if (result.isConfirmed) {
          navigate("/upload/" + clientID);
        }
      });
    }
  };

  // BULK USER-ATTRIBUTES ASSIGNMENT
  async function assignUserAttributes(attributeId: string, value: string) {
    if (clientID && selectedImageIds) {
      try {
        const assignedAttributesResult = await assignAttributes(
          clientID,
          selectedImageIds,
          attributeId,
          value
        );
        if (
          assignedAttributesResult &&
          assignedAttributesResult !== "success"
        ) {
          console.error("User Attributes not assigned");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Attributes could not be assigned.",
          });
          return;
        } else {
          clearSelection();
          Swal.fire({
            title: "Success",
            text: "Attributes successfully assigned",
            icon: "success",
          });
        }
      } catch (error) {
        console.error("Error, User Attributes not assigned", error);
      }
    }
  }

  // IMAGE DATA CSV HANDLE
  const getBulkImagesData = async () => {
    if (clientID && selectedBulkImageIds) {
      const excelDocument = await fetchCsvDocument(
        clientID,
        selectedBulkImageIds
      );

      if (excelDocument.statusCode === 202) {
        Swal.fire({
          icon: "info",
          iconColor: "#183659",
          title: "Image Attribute Download",
          text: "Requested attributes will be sent to your email shortly. You may close this popup.",
          allowOutsideClick: true,
          allowEscapeKey: true,
          showConfirmButton: false,
          showCancelButton: true,
          cancelButtonText: "Close",
          cancelButtonColor: "#183659",
          // didOpen: () => {
          //   Swal.showLoading();
          // },
        });
      } else {
        console.error(
          "Error downloading bulk images error, code:",
          excelDocument.statusCode
        );
        Swal.fire({
          title: "Download Error",
          icon: "error",
          iconColor: "#BB2D3B",
          text: "An error has occurred during image download",
          allowOutsideClick: false,
        });
      }
    } else {
      console.error(
        "Missing clientID and/or selected image ID's for bulk image data download"
      );
      Swal.fire({
        title: "Download Error",
        icon: "error",
        iconColor: "#BB2D3B",
        text: "An error has occurred during image download",
        allowOutsideClick: false,
      });
    }
  };

  return (
    <>
      <div
        className="bulkMenu profileMenu"
        onMouseEnter={() => bulkMenuMouseOutHandler(true)}
        onMouseLeave={() => bulkMenuMouseOutHandler(false)}
      >
        {/* DOWNLOADS */}
        <div className="profileContent" onClick={handleBulkDownload}>
          <span className="textContent">Download</span>
        </div>
        {/* MOVE */}
        {selectType === "board" && (
          <div className="profileContent" onClick={moveMenuHandler}>
            <span className="textContent">Move</span>
            {isMoveMenuVisible && (
              <div className="custom_context_menu custom_context_menu_move">
                <span className="uk-text-bold">Move</span>
                {allBoards.length > 0 ? (
                  allBoards.map((board) => (
                    <div
                      className="custom_context_menu_item custom_context_menu_item_button"
                      onClick={() => {
                        handleMoveImages(board.id);
                      }}
                      key={board.id}
                    >
                      {board.title}
                    </div>
                  ))
                ) : (
                  <div className="uk-text-bold">No boards available</div>
                )}
              </div>
            )}
          </div>
        )}
        {/* COPY / ADD */}
        <div className="profileContent" onClick={copyMenuHandler}>
          <span className="textContent">
            {selectType === "client" ? "Add" : "Copy"}
          </span>
          {isCopyMenuVisible && (
            <div className="custom_context_menu custom_context_menu_copy">
              <span className="uk-text-bold">
                {selectType === "client" ? "Add" : "Copy"}
              </span>
              {allBoards.length > 0 ? (
                allBoards.map((board) => (
                  <div
                    className="custom_context_menu_item custom_context_menu_item_button"
                    onClick={() => {
                      if (selectType === "client") {
                        console.log("client", board.id);
                        handleAddImages(board.id);
                      } else if (selectType === "board") {
                        handleCopyImages(board.id);
                      }
                    }}
                    key={board.id}
                  >
                    {/* <span className={`data-uk-tooltip title=${board.title}`}> */}
                    {board.title.length > 15 ? (
                      <span
                        uk-tooltip={`title: ${board.title}; pos: top-right`}
                      >
                        {board.title}
                      </span>
                    ) : (
                      <span>{board.title}</span>
                    )}
                  </div>
                ))
              ) : (
                <div className="uk-text-bold">No boards available</div>
              )}
            </div>
          )}
        </div>
        {/* ADD KEYWORD */}
        <div
          className="profileContent"
          onClick={() => {
            keywordMenuHandler();
            keyWordAlert();
          }}
        >
          <span className="textContent">Add keyword</span>
        </div>
        {/* DELETE */}
        {selectType === "board" && (
          <div
            className="profileContent"
            onClick={() => {
              deleteMenuHandler();
              handleDeleteMessage();
            }}
          >
            <span className="textContent">Remove</span>
          </div>
        )}
        {/* ASSIGN USER ATTRIBUTES */}
        <div className="profileContent" onClick={callAssignUserAttributes}>
          <span className="textContent">Assign Attributes</span>
        </div>
        {/* IMAGE DATA CSV DOWNLOAD */}
        <div className="profileContent" onClick={getBulkImagesData}>
          <span className="textContent">Download images data</span>
        </div>
        {/* UNSELECT */}
        <div className="profileContent" onClick={clearSelection}>
          <span className="textContent">Unselect</span>
        </div>
      </div>
    </>
  );
};

export default BulkMenu;
