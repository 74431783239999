import React, { useEffect, useState } from "react";
import { ImageType, ManageAttributes } from "../../types";
import { ReactComponent as DeleteAttribute } from "../../assets/images/icons/trash-can.svg";
import "./singleImage.scss";
import { removeAttributeFromImage } from "../../models/attributes.model";
import Swal from "sweetalert2";

type ImageAttributesProps = {
  clientID: string | null;
  imageID: string | null;
  imageObject: object[] | null;
  userAttributes: ManageAttributes[] | null;
};

const ImageAttributes: React.FC<ImageAttributesProps> = ({
  clientID,
  imageID,
  imageObject,
  userAttributes,
}) => {
  // Boolean Control to Display Attribute Removal Div below selected user-attribute row
  const [openRemovalDisplay, setOpenRemovalDisplay] = useState<Boolean>(false);
  // Boolean control -> Display invalid 'removal' confirmation
  const [showInvalidRemovalMessage, setShowInvalidRemovalMessage] =
    useState<Boolean>(false);
  // Set Default display message for invalid user input
  // allowing for future change if needed
  const [invalidInputText, setInvalidInputText] = useState(
    "Please type 'REMOVE' to remove attribute"
  );
  // Selected User Attribute for Removal
  const [keyAttributeRemove, setKeyAttributeRemove] = useState<string>("");
  // attribute entries from image Object
  const [attributeEntries, setAttributeEntries] = useState<[string, string][]>(
    []
  );
  // boolean holding presence of User Attributes assigned to image
  const [hasUserAttributes, setHasUserAttributes] = useState<boolean>(false);

  // Retrieve all assigned attributes for image, flatten to single array
  // Determine presence of any assigned user-attributes
  useEffect(() => {
    if (imageObject) {
      const entries = imageObject.flatMap(
        (obj) => Object.entries(obj) as [string, string][]
      );
      setAttributeEntries(entries);

      // determine if user-assigned attributes present
      if (userAttributes && !hasUserAttributes) {
        const hasMatchingAttributes = userAttributes.some((attribute) =>
          entries.some(([key]) => attribute.code === key)
        );
        setHasUserAttributes(hasMatchingAttributes);
      }
    }
  }, [imageObject, userAttributes]);

  if (!imageObject || imageObject === null) {
    return <p>No attributes to display.</p>;
  }

  const renderValue = (value: any): React.ReactNode => {
    // Check if the value is an object, and if so, render it as JSON, otherwise as is
    if (typeof value === "object" && value !== null) {
      return <pre>{JSON.stringify(value, null, 2)}</pre>;
    }
    return value.toString();
  };

  // Boolean check if current attribute is a user-attribute
  const checkAttributeType = (key: any) => {
    if (userAttributes?.length === 0) return false;

    if (key && typeof key === "string") {
      if (userAttributes) {
        const hasAttribute =
          userAttributes.find((attribute) => attribute.code === key) !==
          undefined;
        return hasAttribute;
      }
    }
    return false;
  };

  // Open removal div for user-attributes
  const callForAttributeDeletion = (key: string) => {
    setShowInvalidRemovalMessage(false);
    setOpenRemovalDisplay(openRemovalDisplay === true ? false : true);
  };

  // Removal Confirmation -> User must type 'REMOVE'
  const validateRemoval = () => {
    let confirmRemovalText = (
      document.getElementById("confirmRemoveInput") as HTMLInputElement
    ).value;

    if (!confirmRemovalText) {
      setShowInvalidRemovalMessage(true);
    } else if (confirmRemovalText.trim() !== "REMOVE") {
      setShowInvalidRemovalMessage(true);
    } else {
      // Call for removal of attribute
      handleAttributeRemove();
    }
  };

  const handleAttributeRemove = async () => {
    // remove possible error display for 'remove' confirm message
    setShowInvalidRemovalMessage(false);

    if (keyAttributeRemove && userAttributes) {
      const selectedAttribute = userAttributes.filter((attribute) => {
        return attribute.code === keyAttributeRemove;
      });
      if (selectedAttribute.length > 1) {
        console.error(
          "Attribute Key (name) not unique for user-attribute removal from image"
        );
      } else {
        if (clientID && imageID) {
          await removeAttribute(clientID, selectedAttribute[0].id, imageID);
        } else {
          console.error(
            "ClientID and/or imageID not provided for removal of user-attribute"
          );
        }
      }
    }
  };

  // call for removal of user attributes
  const removeAttribute = async (
    clientID: string,
    attributeID: number,
    imageID: string
  ) => {
    if (clientID && attributeID && imageID) {
      try {
        Swal.fire({
          title: "Removing Attribute...",
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const results = await removeAttributeFromImage(
          clientID,
          attributeID,
          imageID
        );
        if (results && results === "success") {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Attribute Removed",
            showConfirmButton: true,
            confirmButtonText: "Close",
          }).then((result) => {
            window.location.reload();
          });
        } else {
          console.error("Values not provided for user-attribute removal");
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Attribute could not be removed.",
            showConfirmButton: true,
            confirmButtonText: "Close",
          }).then((result) => {
            window.location.reload();
          });
        }
      } catch (error) {
        console.error("Attribute could not be removed", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Attribute could not be removed.",
          showCloseButton: true,
        });
      }
    }
    setOpenRemovalDisplay(false);
  };

  return (
    <div className="attributes-table-container ">
      <table className="singleViewTable">
        <thead>
          <tr>
            <th className="table_column">Attribute</th>
            <th>Value</th>
            {/* Removal Option for User-Attributes Only */}
            {hasUserAttributes ? <th></th> : null}
          </tr>
        </thead>
        <tbody>
          {imageObject.length === 0 ? (
            <tr>
              <td colSpan={2}>No attributes to display.</td>
            </tr>
          ) : (
            attributeEntries.map(([key, value]) => {
              return (
                <React.Fragment>
                  <tr
                    className={`${
                      openRemovalDisplay && key === keyAttributeRemove
                        ? "removal-row"
                        : ""
                    }`}
                    key={key}
                  >
                    <td
                      className={`${
                        openRemovalDisplay && key === keyAttributeRemove
                          ? "remove-item"
                          : ""
                      }`}
                    >
                      {key}
                    </td>
                    <td
                      className={`${
                        openRemovalDisplay && key === keyAttributeRemove
                          ? "remove-item"
                          : ""
                      }`}
                    >
                      {renderValue(value)}
                    </td>
                    {hasUserAttributes ? (
                      <td>
                        {checkAttributeType(key) ? (
                          <DeleteAttribute
                            className="delete-attribute"
                            onClick={() => {
                              callForAttributeDeletion(key);
                              setKeyAttributeRemove(key);
                            }}
                          />
                        ) : null}
                      </td>
                    ) : null}
                  </tr>
                  <tr
                    className={`${
                      openRemovalDisplay && key === keyAttributeRemove
                        ? "removal-div-border"
                        : ""
                    }`}
                  >
                    {openRemovalDisplay && key === keyAttributeRemove && (
                      <td colSpan={3}>
                        <div className="removal_div">
                          <input
                            className="removal_input"
                            id="confirmRemoveInput"
                            type="text"
                            autoComplete="off"
                            placeholder="Please type 'REMOVE'"
                          ></input>
                          <button
                            className="removal_submit"
                            onClick={() => {
                              validateRemoval();
                            }}
                          >
                            REMOVE
                          </button>
                        </div>
                        {/* Error message for invalid "REMOVE" input confirmation */}
                        <div className="removal-error-container">
                          {showInvalidRemovalMessage === true ? (
                            <label id="removal-error-message">
                              {invalidInputText}
                            </label>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    )}
                  </tr>
                </React.Fragment>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ImageAttributes;
