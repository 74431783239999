import React, {
  useState,
  useContext,
  createContext,
  useRef,
  useEffect,
} from "react";
import { ManageAttributes, userAttributeContextType } from "../types";
import { fetchAttributes } from "../models/wsc.model";

// track updates (creation, deletion and assignment of attributes) to user-created attributes
// NOTE: Current Implementation: UserAttributeContext has been wrapped at 'App' Level

export const UserAttributeContext = createContext<
  userAttributeContextType | undefined
>(undefined);

export const useAttributeContext = () => {
  const context = useContext(UserAttributeContext);
  if (!context) {
    throw new Error(
      "UserAttributeContext must be used within a UserAttributeContext provider"
    );
  }
  return context;
};

export const UserAttributeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [attributeData, setAttributeData] = useState<ManageAttributes[]>([]);
  const [clientID, setClientID] = useState<string>("");
  const [allAttributes, setAllAttributes] = useState<ManageAttributes[]>([]);

  // Retrieve Attributes, filter and keep only user created attributes
  const updateAttributeData = async (client_ID: string): Promise<string> => {
    console.log("Updating");
    setClientID(client_ID);
    const newAttributeData = await fetchAttributes(client_ID);

    if (newAttributeData) {
      if (typeof newAttributeData === "string") {
        console.error("Image User-Attributes could not be fetched");
        return "error";
      } else {
        // keep all attributes separate
        setAllAttributes(newAttributeData);
        // keep user attributes only (type = 3)
        const filteredAttributes = newAttributeData.filter(
          (attribute) => attribute.type === 3
        );
        setAttributeData(filteredAttributes);
        return "success";
      }
    } else {
      console.error("Image User-Attributes could not be fetched");
      return "error";
    }
  };

  // getUserAttributes will first check for change in clientID (loadUp or switching between clients)
  // and update user and all attributes matching those of the client
  const getAttributeData = async (
    client_id: string
  ): Promise<ManageAttributes[]> => {
    // Client Changed, reload data for the new client

    if (clientID !== client_id) {
      // first perform attribute updates before returning (filtered) user attribute data
      updateAttributeData(client_id);
    }
    return attributeData;
  };

  return (
    <UserAttributeContext.Provider
      value={{
        clientID,
        attributeData,
        allAttributes,
        updateAttributeData,
        getAttributeData,
      }}
    >
      {children}
    </UserAttributeContext.Provider>
  );
};
